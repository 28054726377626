export const typoClasses = {
  text: {
    color: "white",
    "fontSize": {
      xs: "16px",
      sm: "18px",
      md: "20px",
      lg: "30px",
    },
  },
};
